import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";

export const add_to_card = createAsyncThunk(
    'card/add_to_card',
    async (info,{rejectWithValue,fulfillWithValue}) => {
        try {
            const {data} = await api.post('/product/add-to-card',info, {withCredentials: true})
            return fulfillWithValue(data);
        } catch (error){
            return rejectWithValue(error.response.data);
        }
    }
)
export const update_card = createAsyncThunk(
    'card/update_card',
    async (info,{rejectWithValue,fulfillWithValue}) => {
        try {
            const {data} = await api.post('/product/update-card',info, {withCredentials: true})
            return fulfillWithValue(data);
        } catch (error){
            return rejectWithValue(error.response.data);
        }
    }
)
export const remove_card = createAsyncThunk(
    'card/remove_card',
    async (info,{rejectWithValue,fulfillWithValue}) => {
        console.log('remove_card',info);
        
        try {
            const {data} = await api.post('/product/remove-card',info, {withCredentials: true})
            return fulfillWithValue(data);
        } catch (error){
            return rejectWithValue(error.response.data);
        }
    }
)


export const view_card = createAsyncThunk(
    'card/view_card',
    async (_,{rejectWithValue,fulfillWithValue}) => {
        try {
            const {data} = await api.get('/product/view-card', {withCredentials: true})
            return fulfillWithValue(data);
        } catch (error){
            return rejectWithValue(error.response.data);
        }
    }
)


export const add_to_wishlist = createAsyncThunk(
    'card/add_to_wishlist',
    async (info,{rejectWithValue,fulfillWithValue}) => {
        try {
            const {data} = await api.post('/product/add-to-wishlist',info, {withCredentials: true})
            return fulfillWithValue(data);
        } catch (error){
            return rejectWithValue(error.response.data);
        }
    }
)


export const view_wishlist = createAsyncThunk(
    'card/view_wishlist',
    async (_,{rejectWithValue,fulfillWithValue}) => {
        try {
            const {data} = await api.get('/product/view-wishlist', {withCredentials: true})
            return fulfillWithValue(data);
        } catch (error){
            return rejectWithValue(error.response.data);
        }
    }
)



export const cardReducer = createSlice({
    name: 'card',
    initialState: {
       card_products: [],
       card_product_count: 0,
       wishlist_count: 0,
       wishlist:[],
       price:0,
       successMessage: '',
       errorMessage: '',
       shipping_fee:0,
       outofstock_products: []
    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ""
            state.successMessage = ""
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(add_to_card.rejected, (state, { payload }) => {
            state.errorMessage = payload.error 
        }).addCase(add_to_card.fulfilled, (state, { payload }) => {
            state.successMessage = payload.message;
            if (payload.product) {
                state.card_products = []
                if (payload.product.length > 0) {
                    state.card_products = payload.product
                }else{
                    state.card_products.push(payload.product)
                }
                
                state.card_products && state.card_products.map((e,i)=>{
                    state.card_product_count = state.card_product_count + e.quantity
                })
            }

        })

        .addCase(update_card.rejected, (state, { payload }) => {
            state.errorMessage = payload.error 
        }).addCase(update_card.fulfilled, (state, { payload }) => {
            state.successMessage = payload.message;
            if (payload.product) {
                state.card_products = []
                if (payload.product.length > 0) {
                    state.card_products = payload.product
                }else{
                    state.card_products.push(payload.product)
                }
                
                state.card_products && state.card_products.map((e,i)=>{
                    state.card_product_count = state.card_product_count + e.quantity
                })
            }

        })

        .addCase(remove_card.rejected, (state, { payload }) => {
            state.errorMessage = payload.error 
        }).addCase(remove_card.fulfilled, (state, { payload }) => {
            state.successMessage = payload.message;
            if (payload.product) {
                state.card_products = []
                if (payload.product.length > 0) {
                    state.card_products = payload.product
                }else{
                    state.card_products.push(payload.product)
                }
                
                state.card_products && state.card_products.map((e,i)=>{
                    state.card_product_count = state.card_product_count + e.quantity
                })
            }

        })

        .addCase(view_card.rejected, (state, { payload }) => {
            state.errorMessage = payload.error 
        }).addCase(view_card.fulfilled, (state, { payload }) => {
            state.successMessage = payload.message;
            if (payload.product) {
                if (payload.product.length > 0) {
                    state.card_products = payload.product
                }else{
                    state.card_products.push(payload.product)
                }
                
                state.card_products && state.card_products.map((e,i)=>{
                    state.card_product_count = state.card_product_count + e.quantity
                })
            }

        })


        .addCase(add_to_wishlist.rejected, (state, { payload }) => {
            state.errorMessage = payload.error 
        }).addCase(add_to_wishlist.fulfilled, (state, { payload }) => {
            state.successMessage = payload.message;
            if (payload.product) {
                state.wishlist = []
                if (payload.product.length > 0) {
                    state.wishlist = payload.product
                }else{
                    state.wishlist.push(payload.product)
                }
                
                state.wishlist && state.wishlist.map((e,i)=>{
                    state.wishlist_count = state.wishlist_count + e.quantity
                })
            }

        })
        
        
        .addCase(view_wishlist.rejected, (state, { payload }) => {
            state.errorMessage = payload.error 
        }).addCase(view_wishlist.fulfilled, (state, { payload }) => {
            state.successMessage = payload.message;
            if (payload.product) {
                if (payload.product.length > 0) {
                    state.wishlist = payload.product
                }else{
                    state.wishlist.push(payload.product)
                }
                
                state.wishlist && state.wishlist.map((e,i)=>{
                    state.wishlist_count = state.wishlist_count + e.quantity
                })
            }
        })


    }
})

export default cardReducer.reducer

export const {messageClear} = cardReducer.actions